// ArchivalCards.js
import React from "react";
import GridCard from "./GridCard";

const ArchivalCards = () => {
	const gridCardsData = [
		{
			imageSrc: "/media/archival_media/archivalMediaQ22024.jpg",
			heading: "Q2 2024",
			caption: "PsyNet Recordings and Brain Worm Records",
			link: "/archival-media/",
		},

		{
			imageSrc: "/media/archival_media/archivalMediaQ12024.jpg",
			heading: "Q1 2024",
			caption: "Cortex Lab and PsyNet Debugging",
			link: "/archival-media/",
		},

		{
			imageSrc: "/media/archival_media/archivalMediaQ42023.jpg",
			heading: "Q4 2023",
			caption: "Twin Participant Records and delcomimages",
			link: "/archival-media/",
		},
		{
			imageSrc: "/media/archival_media/archivalMedia.jpg",
			heading: "Q3 2023",
			caption: "Careers Transparency Report and Incident Report 7",
			link: "/archival-media/",
		},
	];

	return (
		<div>
			<div className="title-container">
				<h2>Archival Media.</h2>
				<p>Information disclosure.</p>
			</div>
			<div className="grid-container">
				{gridCardsData.map((data, index) => (
					<GridCard
						key={index}
						heading={data.heading}
						imageSrc={data.imageSrc}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default ArchivalCards;
