import React from "react";
import Portal from "./Portal";
import PortalNewsHiringEnd, { title as endTitle } from "./PortalNewsHiringEnd";
import portalNewsFlipper, { title as flipperTitle } from "./PortalNewsFlipper";
import PortalVisitorFooter from "./PortalVisitorFooter";
import PortalNewsAgusutaLostFound, {
	title as lostFoundTitle,
} from "./PortalNewsAgusutaLostFound";

const PortalVisitors = () => {
	const tabs = [
		{ id: "home", label: "Home" },
		{ id: "jobOpportunities", label: "Job Opportunities" },
	];

	const newsArticles = [
		{ component: PortalNewsAgusutaLostFound, title: lostFoundTitle },
		{ component: PortalNewsHiringEnd, title: endTitle },
		{ component: portalNewsFlipper, title: flipperTitle },
	];

	return (
		<Portal
			category="Visitor"
			tabs={tabs}
			newsArticles={newsArticles}
			footerComponent={<PortalVisitorFooter />}
		/>
	);
};

export default PortalVisitors;
