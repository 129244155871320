import React, { useState, useRef, useEffect } from "react";
import SmartURL from "./SmartURL";

const BrainWormsUserManual = ({ style, fontColorHighlight }) => {
	const [activeSection, setActiveSection] = useState("about");
	const contentRef = useRef(null);
	const showSection = (section) => {
		setActiveSection(section);
	};

	useEffect(() => {
		if (contentRef.current) {
			contentRef.current.scrollTop = 0;
		}
	}, [activeSection]);

	const itemContainerStyle = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		top: 0,
		left: 0,
		marginTop: "5%",
		marginLeft: "4%",
		paddingRight: "5%",
		overflowY: "auto",
		height: "83%",
	};

	const navStyle = {
		listStyle: "none",
		padding: 0,
		margin: 0,
		marginTop: "2%",
	};

	const navItemStyle = {
		display: "inline-block",
		paddingLeft: "2%",
		paddingRight: "2%",
		cursor: "pointer",
	};
	const linkStyle = {
		textDecoration: "none",
		color: "rgb(0 183 224)",
	};
	const activeLinkStyle = {
		...linkStyle,
		color: "rgb(130 247 255)",
	};

	const sectionStyle = {
		display: activeSection ? "none" : "block",
	};

	const activeSectionStyle = {
		...itemContainerStyle,
		display: "block",
		color: fontColorHighlight,
		ref: contentRef,
	};

	const ulStyle = {
		padding: 0,
		margin: 0,
		marginLeft: "2%",
	};

	const titleStyle = {
		color: "rgb(130 247 255)",
	};
	return (
		<div style={style}>
			<style>
				{`
                .hover-link:hover {
                    color: rgb(130 247 255) !important;
                    text-decoration: underline !important;
                }
                `}
			</style>
			<div style={navStyle}>
				<div style={navStyle}>
					<ul style={ulStyle}>
						<li style={navItemStyle}>
							<a
								style={activeSection === "about" ? activeLinkStyle : linkStyle}
								className="hover-link"
								onClick={() => showSection("about")}
							>
								About
							</a>
						</li>
						<li style={navItemStyle}>
							<a
								style={activeSection === "stats" ? activeLinkStyle : linkStyle}
								className="hover-link"
								onClick={() => showSection("stats")}
							>
								Stats
							</a>
						</li>
						<li style={navItemStyle}>
							<a
								style={
									activeSection === "resources" ? activeLinkStyle : linkStyle
								}
								className="hover-link"
								onClick={() => showSection("resources")}
							>
								Resources
							</a>
						</li>
						<li style={navItemStyle}>
							<a
								style={activeSection === "combat" ? activeLinkStyle : linkStyle}
								className="hover-link"
								onClick={() => showSection("combat")}
							>
								Combat
							</a>
						</li>
						<li style={navItemStyle}>
							<a
								style={
									activeSection === "evolution" ? activeLinkStyle : linkStyle
								}
								className="hover-link"
								onClick={() => showSection("evolution")}
							>
								Evolution
							</a>
						</li>
						<li style={navItemStyle}>
							<a
								style={
									activeSection === "graphics" ? activeLinkStyle : linkStyle
								}
								className="hover-link"
								onClick={() => showSection("graphics")}
							>
								Graphics
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div
				ref={contentRef}
				style={activeSectionStyle}
				className="scrollbar-container"
			>
				{activeSection === "about" && (
					<div>
						<p>
							<span style={titleStyle}>Brain Worms:</span>
						</p>
						<p>
							Brain Worms are a fully onchain generative brain parasite system
							built on ERC-404 by{" "}
							<a style={linkStyle} href="/about" className="hover-link">
								Del Complex
							</a>
							.
						</p>
						<p>
							Our long term goal is to genetically engineer parasitic worms to
							secrete cognitive enhancing biomolecules. Thus increasing the
							intelligence of the host human. With Brain Worms, we will
							accelerate the human species in order to compete with AGI.
						</p>
						<p>
							Apply to become a{" "}
							<a style={linkStyle} className="hover-link" href="/humansubject">
								human subject
							</a>{" "}
							today.
						</p>
					</div>
				)}

				{activeSection === "stats" && (
					<div>
						<p style={titleStyle}>Stats:</p>
						<p>
							Each Brain Worm has it's own strengths and weaknesses, and becomes
							more powerful by harvesting onchain resources from various areas
							of the synthetic brain they live in:
						</p>
						<p>
							<span style={titleStyle}>Stamina</span> - combat survival and
							health
						</p>
						<p>
							<span style={titleStyle}>Strength</span> - attack damage and hit
							chance
						</p>
						<p>
							<span style={titleStyle}>Dexterity</span> - dodge chance and first
							hit chance
						</p>
						<p>
							Brain Worms are minted with randomized stats, as well as traits.
							Some traits like mandible mouths or thorny legs are biologically
							driven by their DNA. While other traits like fangs and agile legs
							are driven by stats, and displayed by only the most powerful
							worms.
						</p>
					</div>
				)}
				{activeSection === "resources" && (
					<div>
						<p style={titleStyle}>Resources:</p>
						<p>
							Brain Worms live in an onchain virtual brain and its cerebrospinal
							fluid is rich with resources. Harvest these scarce resources to
							empower your Brain Worm!
						</p>
						<p>
							Glucose adds Stamina, Lipids adds Strength, and Amino Acids adds
							Dexterity. However, only one resource may be harvested and used at
							a time.
						</p>
						<p>
							So be strategic! Will you boost your weakest stat, or double down
							on your strongest?
						</p>
						<p style={titleStyle}>Resource Ranking:</p>
						<p>
							Resources grant up to a +4 bonus to a stat depending on your
							resource rank. This ranking is based on the average resource level
							harvested by all other worms. As you rise in rank, the amount of
							resources yielded per harvest decreases.
						</p>
						<p>
							This means that as you start, resources are easier to gain and you
							can catch up to the crowd. While simultaneously preventing the
							most powerful Brain Worms from becoming unstoppable.
						</p>
						<p>
							Harvesting too much at once will impact your yield. So harvest
							often!
						</p>
					</div>
				)}
				{activeSection === "combat" && (
					<div>
						<p style={titleStyle}>Combat System:</p>
						<p>
							Brain Worms compete in global onchain Player vs Player combat to
							determine the most powerful organisms. There are 11 PVP titles,
							and each 100 points earned gives a +1 bonus to all stats.
						</p>
						<p>
							You can only earn PVP Title Points from a worm within 100 points
							of yours, and the amount earned or lost is based on your rank.
							Fighting higher rank worms yields more points, and likewise,
							losing to lower rank worms is costly!
						</p>
						<p>
							Combat is a turn based RPG-like system that occurs in one
							transaction onchain. There are two attack types and two combat
							stances which you can mix:
						</p>
						<p>
							Bite Attack adds +1 strength
							<br />
							Sting Attack adds +1 dexterity
						</p>
						<p>
							Offensive Stance adds +2 strength
							<br />
							Defensive Stance adds +2 dexterity
						</p>
						<p>
							So a Bite in Offensive Stance gives a +3 strength bonus for that
							battle. Mix and match these to strategize against each opponent!
						</p>
						<p>
							Additionally, Drain attacks can be performed and steal the
							resources of your opponents!{" "}
						</p>
						<p style={titleStyle}>Combat Turns:</p>
						<p>
							At the start of combat, each Brain Worm rolls to see who goes
							first, then attacks their opponent. If the defender survive, they
							counter attack. Whichever Brain Worm has the most stamina at the
							end of the battle is victorious. However, if both miss, the
							attacker loses by default. This balances the inherent advantage
							attackers gain on initiative rolls.
						</p>
						<p style={titleStyle}>Combat Details:</p>
						<p>
							Each action in combat is driven by a dice roll, and a modifier.
							The dice rolls are either a 20 sided dice (d20) or a 10 sided dice
							(d10). And the modifiers are either half, or all of a stat. Combat
							is calculated turn by turn in this order:
						</p>
						<br />
						<p style={titleStyle}>Roll for Initiative:</p>
						<p>
							Attacker rolls d20 + full dexterity
							<br />
							&gt;
							<br />
							Defender rolls d20 - half stamina
						</p>
						<p>
							The Brain Worm that got initiative then rolls to see if their hit
							is a critical hit.
						</p>
						<br />
						<p style={titleStyle}>Attacker rolls for Critical Hit:</p>
						<p>
							Attacker rolls d20 + half dexterity
							<br />
							&gt;
							<br />
							22
						</p>
						<p>
							Critical hits are an instant kill. If no critical hit occured, the
							defender attempts to dodge.
						</p>
						<br />
						<p style={titleStyle}>Hit or Dodge:</p>
						<p>
							Attacker rolls d20 + full strength
							<br />
							&gt;
							<br />
							Defender rolls d20 + full dexterity
						</p>
						<p>
							If the hit lands, damage is calculated. If the dodge is
							successful, the defender takes no damage.
						</p>
						<br />
						<p style={titleStyle}>Damage from hit:</p>
						<p>Attacker rolls d10 + half strength</p>
						<p>
							If the defender is still alive, it counter attacks and rolls for a
							critical hit, etc. The Brain Worm with the greatest remaining
							stamina is the victor.
						</p>
					</div>
				)}
				{activeSection === "evolution" && (
					<div>
						<p style={titleStyle}>ERC-404 & Evolution:</p>
						<p>
							Powered by ERC-404, Brain Worms are both a coin, and an NFT. And
							they can be rerolled with new traits and abilities. When fractions
							of Brain Worms are transferred, Brain Worms are burned and
							reminted with new traits. However, the more a Brain Worm is
							rerolled, the more it's stats degrade.
						</p>
						<p style={titleStyle}>Examples:</p>
						<p>
							You can buy, sell, transfer and swap Brain Worms in fractions like
							you would any other coin.
						</p>
						<p>
							For every 1 Brain Worm you own, 1 Brain Worm NFT is minted to your
							wallet.
						</p>
						<p>
							If you have 1 Brain Worm and sell 0.5 , the NFT is burned into a
							burn pool.
						</p>
						<p>
							Likewise, if you have 0.5 Brain Worm and buy 0.5, a new NFT with
							new stats and traits is minted to your wallet.
						</p>
						<p>
							Each time Brain Worms are burned and reminted, their Generation
							count increases. However each generation takes a -1 penalty to a
							random stat.
						</p>
						<p>
							This means that if you value the traits and stats of your Brain
							Worm you can buy, sell, and transfer the NFT representation on
							sites like OpenSea. If you want to trade it like a coin, or get
							quick liquidity from your NFT, you can swap it via Uniswap.
						</p>
					</div>
				)}
				{activeSection === "graphics" && (
					<div>
						<p style={titleStyle}>3D Organism:</p>
						<p>
							Each Brain Worm is a unique, fully onchain generative 3D synthetic
							organism. They can be downloaded and viewed in the browser, or in
							augmented reality on an iPhone or Vision Pro.
						</p>
						<p>
							The Brain Worm cartridges are likewise, fully onchain generative
							works unto themselves. Based on their DNA, these cartridges
							represent the microscopic colony structure of each Brain Worm.
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default BrainWormsUserManual;
