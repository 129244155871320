import React, { useEffect, useState, useCallback } from "react";
import {
	useWaitForTransactionReceipt,
	useWriteContract,
	useAccount,
	useChainId,
	useSwitchChain,
} from "wagmi";
import { parseEther } from "viem";
import { mainnet, base } from "wagmi/chains";
import ManifoldABI from "../ABI/ManifoldABI.json";

const mintContractAddress = "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";
const claimContractAddress = "0xDb8d79C775452a3929b86ac5DEaB3e9d38e1c006";
const creatorContractAddress = "0x882Dd757E02a3420F003Dc2550FEBf58374797e5";

export function MintNFT({
	isMint = false,
	claimId,
	requiredChainId,
	mintButtonText = "Mint 0.01E",
	claimButtonText = "Claim Now - Free",
	priceOverride,
}: {
	claimId: string | number;
	requiredChainId?: number;
	isMint?: boolean;
	mintButtonText?: string;
	claimButtonText?: string;
	priceOverride?: string;
}) {
	const { data: hash, error, isPending, writeContract } = useWriteContract();
	const { address } = useAccount();
	const chainId = useChainId();
	const { switchChain } = useSwitchChain();

	const targetChainId = requiredChainId || mainnet.id;

	const [isSwitching, setIsSwitching] = useState(false);

	const checkNetwork = useCallback(async () => {
		const provider = (window as any).ethereum;
		if (provider && provider.request) {
			try {
				const currentChainId = await provider.request({
					method: "eth_chainId",
				});
				console.log("Current chainId:", currentChainId);
				return parseInt(currentChainId, 16) === targetChainId;
			} catch (error) {
				console.log("Error checking network:", error);
			}
		}
		return chainId === targetChainId;
	}, [targetChainId, chainId]);

	const handleSwitchNetwork = async () => {
		setIsSwitching(true);
		try {
			await switchChain({ chainId: targetChainId });
		} catch (error: any) {
			console.error("Failed to switch network:", error);
			if (
				error.code === 4001 ||
				(error.message && error.message.includes("User rejected"))
			) {
				console.log("User cancelled the network switch");
			}
		} finally {
			setIsSwitching(false);
		}
	};

	const handleTransaction = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!address) {
			console.error("No wallet connected");
			return;
		}

		const isCorrect = await checkNetwork();
		if (!isCorrect) {
			await handleSwitchNetwork();
			return;
		}

		console.log(
			`${isMint ? "Minting" : "Claiming"} NFT for claimId`,
			claimId,
			"to address",
			address,
			"priceOverride",
			priceOverride
		);

		writeContract({
			address: isMint ? mintContractAddress : claimContractAddress,
			abi: ManifoldABI.abi,
			functionName: "mint",
			args: [creatorContractAddress, BigInt(claimId), 0, [], address],
			value: parseEther(
				priceOverride ? priceOverride : isMint ? "0.0105" : "0.0005"
			),
		});
	};

	const { isLoading: isConfirming, isSuccess: isConfirmed } =
		useWaitForTransactionReceipt({
			hash,
		});

	const getButtonText = () => {
		if (isPending || isConfirming) return "Confirming...";
		if (isSwitching) return "Switching...";
		return isMint ? mintButtonText : claimButtonText;
	};

	return (
		<form onSubmit={handleTransaction}>
			<button
				disabled={isPending || isConfirming || !address || isSwitching}
				type="submit"
				style={{
					borderRadius: "0.5rem",
				}}
			>
				{getButtonText()}
			</button>
		</form>
	);
}

export default MintNFT;
