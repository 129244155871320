import React, { useState, useEffect } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	ReferenceLine,
} from "recharts";

const flashingTextStyle = {
	animation: "flashingText 0.5s linear infinite",
};

import ForceGraph from "./ForceGraph";
import MintToken from "./MintToken";
import WalletConnect from "./WalletConnect";

const ChimeraAccessPortal = () => {
	const [accessCode, setAccessCode] = useState("");
	const [hasAccess, setHasAccess] = useState(false);
	const [error, setError] = useState("");
	const [brainWormData, setBrainWormData] = useState([]);
	const [psyNetData, setPsyNetData] = useState([]);
	const [neurotransmitterData, setNeurotransmitterData] = useState([]);
	const [cognitiveDeclineData, setCognitiveDeclineData] = useState([]);
	const [loadingState, setLoadingState] = useState("idle");
	const [loadingDots, setLoadingDots] = useState("");

	const [alerts, setAlerts] = useState([]);

	const generateAlert = () => {
		const metrics = [
			{ name: "Colony Growth Rate", unit: "%", threshold: 150, critical: 200 },
			{
				name: "PsyNet Signal Integrity",
				unit: "%",
				threshold: 75,
				critical: 50,
			},
			{ name: "BDNF Levels", unit: "ng/mL", threshold: 25, critical: 35 },
			{
				name: "Synaptic Plasticity Index",
				unit: "",
				threshold: 2.5,
				critical: 3.5,
			},
			{ name: "Dopamine Levels", unit: "ng/mL", threshold: 150, critical: 200 },
			{
				name: "Acetylcholine Synthesis",
				unit: "nmol/h/mg",
				threshold: 15,
				critical: 20,
			},
			{
				name: "BBB Permeability",
				unit: "Ki",
				threshold: 0.005,
				critical: 0.01,
			},
			{
				name: "EEG Alpha Wave Amplitude",
				unit: "μV",
				threshold: 50,
				critical: 70,
			},
			{
				name: "Organism Motility",
				unit: "μm/s",
				threshold: 200,
				critical: 300,
			},
			{
				name: "Genetic Safeguard Integrity",
				unit: "%",
				threshold: 95,
				critical: 90,
			},
		];

		const metric = metrics[Math.floor(Math.random() * metrics.length)];
		const value = metric.critical + (Math.random() - 0.5) * 20;
		const timestamp = new Date().toISOString();

		return {
			timestamp,
			metric: metric.name,
			value: value.toFixed(2),
			unit: metric.unit,
			status: value > metric.critical ? "CRITICAL" : "WARNING",
			threshold: metric.threshold,
			critical: metric.critical,
		};
	};

	useEffect(() => {
		if (hasAccess) {
			const alertInterval = setInterval(() => {
				if (alerts.length < 20) {
					setAlerts((prev) => [...prev, generateAlert()]);
				} else {
					clearInterval(alertInterval);
				}
			}, 4500);

			return () => clearInterval(alertInterval);
		}
	}, [hasAccess, alerts]);

	useEffect(() => {
		let dotsInterval;
		if (loadingState !== "idle" && !hasAccess) {
			dotsInterval = setInterval(() => {
				setLoadingDots((prev) => (prev.length >= 3 ? "" : prev + "."));
			}, 500);
		}
		return () => clearInterval(dotsInterval);
	}, [loadingState, hasAccess]);

	useEffect(() => {
		if (hasAccess) {
			const interval = setInterval(() => {
				const now = new Date();
				setBrainWormData((prevData) => [
					...prevData.slice(-29),
					{
						time: now.toLocaleTimeString(),
						count: Math.min(
							4500,
							Math.max(
								3000,
								prevData.length
									? prevData[prevData.length - 1].count +
											(Math.random() - 0.3) * 400
									: 3500
							)
						),
					},
				]);
				setPsyNetData((prevData) => [
					...prevData.slice(-29),
					{
						time: now.toLocaleTimeString(),
						strength: Math.min(
							100,
							Math.max(
								0,
								prevData.length
									? prevData[prevData.length - 1].strength +
											(Math.random() - 0.6) * 20
									: 50
							)
						),
					},
				]);
				setNeurotransmitterData((prevData) => [
					...prevData.slice(-29),
					{
						time: now.toLocaleTimeString(),
						imbalance: Math.min(
							100,
							Math.max(
								0,
								prevData.length
									? prevData[prevData.length - 1].imbalance +
											Math.sin(now.getTime() / 5000) * 10 +
											(Math.random() - 0.5) * 15
									: 70
							)
						),
					},
				]);
				setCognitiveDeclineData((prevData) => [
					...prevData.slice(-29),
					{
						time: now.toLocaleTimeString(),
						rate: Math.min(
							100,
							Math.max(
								0,
								prevData.length
									? prevData[prevData.length - 1].rate +
											(Math.random() - 0.4) * 10
									: 80
							)
						),
					},
				]);
			}, 200);
			return () => clearInterval(interval);
		}
	}, [hasAccess]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (accessCode === "96708") {
			setLoadingState("connecting");
			setTimeout(() => {
				setLoadingState("initializing");
				setTimeout(() => {
					setLoadingState("critical");
					setTimeout(() => {
						setHasAccess(true);
						setError("");
					}, 2000);
				}, 2000);
			}, 2000);
		} else {
			setError("Invalid access code. Please try again.");
		}
	};

	if (loadingState !== "idle" && !hasAccess) {
		return (
			<div className="article-container">
				<div className="spacerShort"></div>
				<div className="spacerShort"></div>
				<div className="spacerShort"></div>

				<div
					style={{
						textAlign: "center",
						fontSize: "1.2em",
						...(loadingState === "critical" ? flashingTextStyle : {}),
					}}
				>
					{loadingState === "connecting" &&
						`Establishing connection to PsyNet cloud${loadingDots}`}
					{loadingState === "initializing" &&
						`Initializing Brain Worm monitoring systems${loadingDots}`}
					{loadingState === "critical" && `WARNING: Critical events detected`}
				</div>
			</div>
		);
	}

	if (hasAccess) {
		return (
			<div className="article-container">
				<div className="spacerShort"></div>
				<h1 style={{}}>Project Chimera: Participant Monitoring</h1>

				<div
					style={{
						backgroundColor: "#000",
						border: "2px solid #FF0000",
						color: "#FF0000",
						padding: "15px",
						marginBottom: "20px",
						fontFamily: "monospace",
					}}
				>
					<strong
						style={{
							fontSize: "1.2em",
							animation: "flashingText 3s linear infinite",
						}}
					>
						[EMERGENCY ALERT: PROJECT CHIMERA]
					</strong>
					<p style={{ marginTop: "10px", marginBottom: "5px" }}>
						CRITICAL SYSTEM FAILURES DETECTED:
					</p>
					<ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
						<li>• Brain Worm proliferation exceeding containment capacity</li>
						<li>• PsyNet signal interference at dangerous levels</li>
						<li>• Subjects displaying severe cognitive instability</li>
					</ul>
					<p style={{ marginTop: "10px" }}>
						IMMEDIATE ACTION REQUIRED: Initiate emergency shutdown and subject
						isolation procedures. Contact XioSky security for immediate response
						and extraction to BlueDragon facility.
					</p>
				</div>

				<section
					style={{
						border: "1px solid #FF0000",
						padding: "15px",
						marginTop: "20px",
						backgroundColor: "#000",
						fontFamily: "monospace",
					}}
				>
					<h2
						style={{
							color: "#FF0000",
							fontSize: "1.2em",
							fontWeight: "bold",
							marginBottom: "10px",
							textTransform: "uppercase",
						}}
					>
						[SYSTEM ALERTS]
					</h2>
					<div style={{ color: "#FF0000" }}>
						{alerts.map((alert, index) => (
							<div key={index} style={{ marginBottom: "10px" }}>
								[{alert.timestamp}] {alert.status}: {alert.metric}
								<br />
								Value: {alert.value} {alert.unit} (Threshold: {alert.threshold},
								Critical: {alert.critical})
							</div>
						))}
					</div>
				</section>

				<section
					style={{
						border: "1px solid #FF0000",
						padding: "15px",
						marginTop: "20px",
						marginBottom: "20px",
						backgroundColor: "#000",
						fontFamily: "monospace",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-start",
						}}
					>
						<div style={{ width: "60%" }}>
							<ForceGraph />
						</div>
						<div
							style={{
								width: "40%",
								textAlign: "left",
								paddingLeft: "20px",
							}}
						>
							<h2
								style={{
									color: "red",
									fontSize: "1.2em",
									fontWeight: "bold",
									marginBottom: "20px",
									marginTop: "20px",
									padding: "0",
								}}
							>
								Connection Pathways - UNSTABLE
							</h2>
							<p>
								Connection pathways compromised. Unable to maintain stable
								network.
							</p>
						</div>
					</div>
				</section>

				<div
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						gap: "20px",
					}}
				>
					<section style={{ border: "1px solid #FF0000", padding: "15px" }}>
						<h2
							style={{
								color: "red",
								fontSize: "1.2em",
								fontWeight: "bold",
								marginBottom: "10px",
							}}
						>
							Brain Worm Colony Status - CRITICAL
						</h2>
						<p>
							Current colony population:{" "}
							{brainWormData.length > 0
								? brainWormData[brainWormData.length - 1].count.toFixed(0)
								: "Loading..."}{" "}
							million (Extreme Proliferation)
						</p>
						<div style={{ height: "200px" }}>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={brainWormData}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="time" />
									<YAxis domain={[2000, 5000]} />
									<Tooltip />
									<ReferenceLine
										y={3000}
										label="Nominal"
										stroke="green"
										strokeDasharray="3 3"
									/>
									<Line
										type="monotone"
										dataKey="count"
										stroke="#FF0000"
										dot={false}
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</section>

					<section style={{ border: "1px solid #FFA500", padding: "15px" }}>
						<h2
							style={{
								color: "orange",
								fontSize: "1.2em",
								fontWeight: "bold",
								marginBottom: "10px",
							}}
						>
							PsyNet Integration - WARNING
						</h2>
						<p>
							Current signal strength:{" "}
							{psyNetData.length > 0
								? psyNetData[psyNetData.length - 1].strength.toFixed(1)
								: "Loading..."}
							% (Severe Degradation)
						</p>
						<div style={{ height: "200px" }}>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={psyNetData}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="time" />
									<YAxis domain={[0, 100]} />
									<Tooltip />
									<Line
										type="monotone"
										dataKey="strength"
										stroke="#FFA500"
										dot={false}
									/>
									<ReferenceLine
										y={95}
										label="Nominal"
										stroke="green"
										strokeDasharray="3 3"
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</section>

					<section style={{ border: "1px solid #FF0000", padding: "15px" }}>
						<h2
							style={{
								color: "red",
								fontSize: "1.2em",
								fontWeight: "bold",
								marginBottom: "10px",
							}}
						>
							Neurotransmitter Imbalance - CRITICAL
						</h2>
						<p>
							Current imbalance level:{" "}
							{neurotransmitterData.length > 0
								? neurotransmitterData[
										neurotransmitterData.length - 1
								  ].imbalance.toFixed(1)
								: "Loading..."}
							% (Severe Disruption)
						</p>
						<div style={{ height: "200px" }}>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={neurotransmitterData}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="time" />
									<YAxis domain={[0, 100]} />
									<Tooltip />
									<Line
										type="monotone"
										dataKey="imbalance"
										stroke="#FF0000"
										dot={false}
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</section>

					<section style={{ border: "1px solid #FF0000", padding: "15px" }}>
						<h2
							style={{
								color: "red",
								fontSize: "1.2em",
								fontWeight: "bold",
								marginBottom: "10px",
							}}
						>
							Cognitive Decline Rate - CRITICAL
						</h2>
						<p>
							Current decline rate:{" "}
							{cognitiveDeclineData.length > 0
								? cognitiveDeclineData[
										cognitiveDeclineData.length - 1
								  ].rate.toFixed(1)
								: "Loading..."}
							% (Rapid Deterioration)
						</p>
						<div style={{ height: "200px" }}>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={cognitiveDeclineData}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="time" />
									<YAxis domain={[0, 100]} />
									<Tooltip />
									<Line
										type="monotone"
										dataKey="rate"
										stroke="#FF0000"
										dot={false}
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</section>
				</div>

				<section
					style={{
						border: "1px solid #FF0000",
						padding: "15px",
						marginTop: "20px",
						backgroundColor: "#000",
						fontFamily: "monospace",
					}}
				>
					<div className="token-container-single">
						<p>BlueDragon Facility Incident 3:</p>
						<p>
							<b>Date of Issue:</b> 14 December 2023
						</p>
						<p>
							<b>Prepared By:</b> Joint Development Task Force – Xio Sky & Del
							Complex
						</p>

						<MintToken
							mintButtonText="Claim Footage"
							priceOverride="0.0005"
							claimId="3824589040"
							imageSrc="/media/archival_media/REDACTEDFacilityIncident3Video.jpg"
							fileSrc="/media/archival_media/REDACTEDFacilityIncident3Video.mp4"
							alt="REDACTED Facility Incident 3 (Video)"
						/>
						<WalletConnect />
					</div>
				</section>

				<section
					style={{
						border: "1px solid #FF0000",
						padding: "15px",
						marginTop: "20px",
						backgroundColor: "#000",
						fontFamily: "monospace",
					}}
				>
					<div className="token-container-single">
						<p>Medical Providers:</p>
						<p>
							Please log in via the{" "}
							<a href="/medicalprovider/login/">medical provider portal</a>
						</p>
					</div>
				</section>
				<section
					style={{
						border: "1px solid #FF0000",
						padding: "15px",
						marginTop: "20px",
						backgroundColor: "#000",
						fontFamily: "monospace",
					}}
				>
					<div className="token-container-single">
						<p>PsyNet Registration:</p>
						<p>
							Pleases redirect all new PsyNet registrations to the{" "}
							<a href="/psynetregister/">PsyNet Registration Portal</a>
						</p>
					</div>
				</section>

				<div className="spacerShort"></div>
			</div>
		);
	}

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<div>
				<h1>Project Chimera Access</h1>
				<form onSubmit={handleSubmit}>
					<div>
						<label htmlFor="accessCode">Enter Access Code</label>
						<input
							type="password"
							id="accessCode"
							value={accessCode}
							onChange={(e) => setAccessCode(e.target.value)}
							placeholder="access code..."
						/>
					</div>
					<button type="submit">Submit</button>
				</form>
				{error && <p>{error}</p>}
			</div>
		</div>
	);
};

export default ChimeraAccessPortal;
