// ArchivalMedia.js
import React, { memo } from "react";
import Article from "./Article";
import TextCard from "./TextCard";
import WalletConnect from "./WalletConnect";
import MintToken from "./MintToken";
import ClaimToken from "./ClaimToken";
import MetaTags from "./MetaTags";
import { ThemeContext } from "../context/ThemeContext";
import { useContext } from "react";
import SmartImg from "./SmartImg";

const ArchivalMedia = memo(() => {
	const { theme, setTheme } = useContext(ThemeContext);
	const isDark = theme === "dark";

	const osSRC = isDark
		? "/media/archival_media/osblack.png"
		: "/media/archival_media/oswhite.png";
	const blurSRC = isDark
		? "/media/archival_media/blurblack.png"
		: "/media/archival_media/blurwhite.png";

	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Learn more about our research",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
	];

	return (
		<div className="article-container">
			<MetaTags
				titleSuffix="Archival Media"
				description="Del Complex is required to disclose certain records (or portions of those records) upon receiving a written request for them, except for records which are exempt from disclosure."
			/>
			<div className="spacerShort"></div>
			<Article
				title="Information disclosure."
				content={[
					"Del Complex is required to disclose certain records (or portions of those records) upon receiving a written request for them, except for records which are exempt from disclosure.",
					"All records are publicly available below. In addition, you may request to receive a unique digital token that is recorded on the Ethereum blockchain for a small fee. This token is both a cryptographic proof of the authenticity of the record, and a copy of the record itself.",
					"The Q2 2024 record distribution period began April 1st 2024, and may be closed at any time.",
				]}
				titleLevel={1}
			/>
			<div className="marketplace-container">
				<a href="https://opensea.io/collection/del-complex-archival-media">
					View entire archive{" "}
					<SmartImg src={osSRC} alt="OpenSea" className="marketplace-logo" />
				</a>
			</div>

			<WalletConnect />

			<h2 className="archiveQuarter"> Q2 2024: </h2>

			<div className="token-container">
				<MintToken
					claimId="26065136"
					imageSrc="/media/archival_media/BrainWorms_Microscopy.jpg"
					fileSrc="/media/archival_media/BrainWorms_microscopy.mp4"
					alt="Brain Worms - Microscopy Recordings (Video)"
				/>

				<MintToken
					claimId="25977072"
					imageSrc="/media/archival_media/PsynetRecording_02_17_2024.jpg"
					fileSrc="/media/archival_media/PsynetRecording_02_17_2024.mp4"
					alt="Cerebral PsyNet Recording (Video)"
				/>

				<MintToken
					claimId="25975024"
					imageSrc="/media/archival_media/Project_REDACTED_Q2 2024_Executive_Update.jpg"
					fileSrc="/media/archival_media/Project_REDACTED_Q2 2024_Executive_Update.pdf"
					alt="Project REDACTED - Q2 2024 Executive Update"
				/>

				<MintToken
					claimId="25970928"
					imageSrc="/media/archival_media/delcomimages_BrainWormsColdStorage.jpg"
					fileSrc="/media/archival_media/delcomimages_BrainWormsColdStorage.jpg"
					alt="DCI - Brain Worms Cold Storage"
				/>
			</div>

			<hr />
			<h2 className="archiveQuarter"> Q1 2024: </h2>

			<div className="token-container">
				<ClaimToken
					claimId="33857776"
					imageSrc="/media/archival_media/cortex_assembly_day.jpg"
					fileSrc="/media/archival_media/cortex_assembly_day.jpg"
					alt="Cortex Lab - Assembly Day"
					active={false}
				/>

				<ClaimToken
					claimId="33853680"
					imageSrc="/media/archival_media/psynet_debugging.jpg"
					fileSrc="/media/archival_media/psynet_debugging.jpg"
					alt="DCI - PsyNet Debugging"
					active={false}
				/>

				<ClaimToken
					claimId="33859824"
					imageSrc="/media/archival_media/psynet_test_subject_7.jpg"
					fileSrc="/media/archival_media/psynet_test_subject_7.jpg"
					alt="DCI - PsyNet Test Subject 7"
					active={false}
				/>
			</div>
			<hr />

			<h2 className="archiveQuarter"> Q4 2023: </h2>

			<div className="token-container">
				<ClaimToken
					claimId="55896304"
					imageSrc="/media/archival_media/bsfcc_networkengineers.jpg"
					fileSrc="/media/archival_media/bsfcc_networkengineers.jpg"
					alt="DCI - BSFCC Network Engineers"
					active={false}
				/>

				<ClaimToken
					claimId="83716336"
					imageSrc="/media/archival_media/redding_cortex_staff.jpg"
					fileSrc="/media/archival_media/redding_cortex_staff.jpg"
					alt="Redding Research Cortex Lab"
					active={false}
				/>

				<ClaimToken
					claimId="83402992"
					imageSrc="/media/archival_media/bsfcc_controls.jpg"
					fileSrc="/media/archival_media/bsfcc_controls.jpg"
					alt="BSFCC Emergency Control Panel"
					active={false}
				/>
			</div>

			<div className="token-container">
				<ClaimToken
					claimId="80177392"
					imageSrc="/media/archival_media/TNSS_4-A7_Participant_Records-1.jpg"
					fileSrc="/media/archival_media/TNSS_4-A7_Participant_Records.pdf"
					alt="Twin Neural Synchronization Study 4-A7: Participant Records"
					active={false}
				/>

				<ClaimToken
					claimId="80181488"
					imageSrc="/media/archival_media/delcomimages_cleanLabTwins_1.jpg"
					fileSrc="/media/archival_media/delcomimages_cleanLabTwins_1.jpg"
					alt="DCI - PsyNet Lab Twins"
					active={false}
				/>

				<ClaimToken
					claimId="80175344"
					imageSrc="/media/archival_media/delcomimages_cleanLabTwins_2.jpg"
					fileSrc="/media/archival_media/delcomimages_cleanLabTwins_2.jpg"
					alt="DCI - Robotics Engineer Twins"
					active={false}
				/>
				<ClaimToken
					claimId="80152816"
					imageSrc="/media/archival_media/delcomimages_tunnel_J.jpg"
					fileSrc="/media/archival_media/delcomimages_tunnel_J.jpg"
					alt="DCI - Tunnel J"
					active={false}
				/>
			</div>
			<hr />

			<h2 className="archiveQuarter"> Q3 2023: </h2>
			<div className="token-container">
				<ClaimToken
					claimId="73439472"
					imageSrc="/media/archival_media/careersTransparencyReport.png"
					fileSrc="/media/archival_media/Del_Complex_Careers_Transparency_Report_September_2023.pdf"
					alt="Careers Transparency Report"
					active={false}
				/>
				<ClaimToken
					claimId="73545968"
					imageSrc="/media/archival_media/incidentReport7.png"
					fileSrc="/media/archival_media/Del_Complex_Incident_Report_7_September_2023.pdf"
					alt="Incident Report 7"
					active={false}
				/>
				<ClaimToken
					claimId="73560304"
					imageSrc="/media/archival_media/incidentReport7_mediaA.jpg"
					fileSrc="/media/archival_media/incidentReport7_mediaA.jpg"
					alt="Incident 7 Media Sample A"
					active={false}
				/>
				<ClaimToken
					claimId="73562352"
					imageSrc="/media/archival_media/incidentReport7_mediaB.jpg"
					fileSrc="/media/archival_media/incidentReport7_mediaB.jpg"
					alt="Incident 7 Media Sample B"
					active={false}
				/>
				<ClaimToken
					claimId="73558256"
					imageSrc="/media/archival_media/incidentReport7_mediaC.jpg"
					fileSrc="/media/archival_media/incidentReport7_mediaC.jpg"
					alt="Incident 7 Media Sample C"
					active={false}
				/>
			</div>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
});

export default ArchivalMedia;
